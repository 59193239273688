/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RangeRequest } from './RangeRequest';
import {
    RangeRequestFromJSON,
    RangeRequestFromJSONTyped,
    RangeRequestToJSON,
} from './RangeRequest';

/**
 * Request body to get filtered companies
 * @export
 * @interface CompanyFilterRequest
 */
export interface CompanyFilterRequest {
    /**
     * 
     * @type {RangeRequest}
     * @memberof CompanyFilterRequest
     */
    company_founded?: RangeRequest | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyFilterRequest
     */
    company_description?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyFilterRequest
     */
    company_industry?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyFilterRequest
     */
    company_industry_group?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyFilterRequest
     */
    company_location_hq_country?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyFilterRequest
     */
    company_location_hq_country_group?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyFilterRequest
     */
    company_name?: string | null;
    /**
     * 
     * @type {RangeRequest}
     * @memberof CompanyFilterRequest
     */
    company_size_employees_count?: RangeRequest | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyFilterRequest
     */
    company_type?: Array<string> | null;
    /**
     * 
     * @type {RangeRequest}
     * @memberof CompanyFilterRequest
     */
    growth_pct_1m?: RangeRequest | null;
    /**
     * 
     * @type {RangeRequest}
     * @memberof CompanyFilterRequest
     */
    growth_pct_3m?: RangeRequest | null;
    /**
     * 
     * @type {RangeRequest}
     * @memberof CompanyFilterRequest
     */
    growth_pct_6m?: RangeRequest | null;
    /**
     * 
     * @type {RangeRequest}
     * @memberof CompanyFilterRequest
     */
    growth_pct_12m?: RangeRequest | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyFilterRequest
     */
    last_round_type?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyFilterRequest
     */
    in_crm?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyFilterRequest
     */
    last_interactors?: Array<string> | null;
    /**
     * 
     * @type {RangeRequest}
     * @memberof CompanyFilterRequest
     */
    days_since_last_interaction?: RangeRequest | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyFilterRequest
     */
    last_interaction_type?: Array<string> | null;
    /**
     * 
     * @type {RangeRequest}
     * @memberof CompanyFilterRequest
     */
    total_money_raised?: RangeRequest | null;
    /**
     * 
     * @type {RangeRequest}
     * @memberof CompanyFilterRequest
     */
    last_round_money_raised?: RangeRequest | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyFilterRequest
     */
    has_crunchbase_data?: boolean | null;
}

/**
 * Check if a given object implements the CompanyFilterRequest interface.
 */
export function instanceOfCompanyFilterRequest(value: object): value is CompanyFilterRequest {
    return true;
}

export function CompanyFilterRequestFromJSON(json: any): CompanyFilterRequest {
    return CompanyFilterRequestFromJSONTyped(json, false);
}

export function CompanyFilterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyFilterRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'company_founded': json['company_founded'] == null ? undefined : RangeRequestFromJSON(json['company_founded']),
        'company_description': json['company_description'] == null ? undefined : json['company_description'],
        'company_industry': json['company_industry'] == null ? undefined : json['company_industry'],
        'company_industry_group': json['company_industry_group'] == null ? undefined : json['company_industry_group'],
        'company_location_hq_country': json['company_location_hq_country'] == null ? undefined : json['company_location_hq_country'],
        'company_location_hq_country_group': json['company_location_hq_country_group'] == null ? undefined : json['company_location_hq_country_group'],
        'company_name': json['company_name'] == null ? undefined : json['company_name'],
        'company_size_employees_count': json['company_size_employees_count'] == null ? undefined : RangeRequestFromJSON(json['company_size_employees_count']),
        'company_type': json['company_type'] == null ? undefined : json['company_type'],
        'growth_pct_1m': json['growth_pct_1m'] == null ? undefined : RangeRequestFromJSON(json['growth_pct_1m']),
        'growth_pct_3m': json['growth_pct_3m'] == null ? undefined : RangeRequestFromJSON(json['growth_pct_3m']),
        'growth_pct_6m': json['growth_pct_6m'] == null ? undefined : RangeRequestFromJSON(json['growth_pct_6m']),
        'growth_pct_12m': json['growth_pct_12m'] == null ? undefined : RangeRequestFromJSON(json['growth_pct_12m']),
        'last_round_type': json['last_round_type'] == null ? undefined : json['last_round_type'],
        'in_crm': json['in_crm'] == null ? undefined : json['in_crm'],
        'last_interactors': json['last_interactors'] == null ? undefined : json['last_interactors'],
        'days_since_last_interaction': json['days_since_last_interaction'] == null ? undefined : RangeRequestFromJSON(json['days_since_last_interaction']),
        'last_interaction_type': json['last_interaction_type'] == null ? undefined : json['last_interaction_type'],
        'total_money_raised': json['total_money_raised'] == null ? undefined : RangeRequestFromJSON(json['total_money_raised']),
        'last_round_money_raised': json['last_round_money_raised'] == null ? undefined : RangeRequestFromJSON(json['last_round_money_raised']),
        'has_crunchbase_data': json['has_crunchbase_data'] == null ? undefined : json['has_crunchbase_data'],
    };
}

export function CompanyFilterRequestToJSON(value?: CompanyFilterRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company_founded': RangeRequestToJSON(value['company_founded']),
        'company_description': value['company_description'],
        'company_industry': value['company_industry'],
        'company_industry_group': value['company_industry_group'],
        'company_location_hq_country': value['company_location_hq_country'],
        'company_location_hq_country_group': value['company_location_hq_country_group'],
        'company_name': value['company_name'],
        'company_size_employees_count': RangeRequestToJSON(value['company_size_employees_count']),
        'company_type': value['company_type'],
        'growth_pct_1m': RangeRequestToJSON(value['growth_pct_1m']),
        'growth_pct_3m': RangeRequestToJSON(value['growth_pct_3m']),
        'growth_pct_6m': RangeRequestToJSON(value['growth_pct_6m']),
        'growth_pct_12m': RangeRequestToJSON(value['growth_pct_12m']),
        'last_round_type': value['last_round_type'],
        'in_crm': value['in_crm'],
        'last_interactors': value['last_interactors'],
        'days_since_last_interaction': RangeRequestToJSON(value['days_since_last_interaction']),
        'last_interaction_type': value['last_interaction_type'],
        'total_money_raised': RangeRequestToJSON(value['total_money_raised']),
        'last_round_money_raised': RangeRequestToJSON(value['last_round_money_raised']),
        'has_crunchbase_data': value['has_crunchbase_data'],
    };
}

