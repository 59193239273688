import { DefaultApi } from "@src/api/apis/DefaultApi";
import { Configuration } from "@src/api/runtime";

const backendHost: string = import.meta.env.VITE_BACKEND_HOST as string;

function getUnAuthenticatedApiInstance() {
  const config = new Configuration({
    basePath: backendHost,
  });

  const apiInstance = new DefaultApi(config);
  return apiInstance;
}

export const unAuthenticatedApiInstance = getUnAuthenticatedApiInstance();

export function getAuthenticatedApiInstance(token: string) {
  const config = new Configuration({
    basePath: backendHost,
    accessToken: `Bearer ${token}`,
  });

  const apiInstance = new DefaultApi(config);
  return apiInstance;
}
