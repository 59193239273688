import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useAuth } from "../auth/authHook";
import { Alert, Button, CircularProgress } from "@mui/material";
import { useApi } from "@src/apiInstance/apiHook";
import { FetchError, ResponseError } from "@src/api/runtime";

// Type Guards
function isResponseError(error: unknown): error is ResponseError {
  return error instanceof ResponseError;
}

function isFetchError(error: unknown): error is FetchError {
  return error instanceof FetchError;
}

/** callbackSchema validates bouncer Auth response query */
const callbackSchema = yup.object({
  code: yup.string().nullable().default(null),
  error: yup.string().nullable().default(null),
  error_description: yup.string().nullable().default(null),
  state: yup.string().nullable().default(null),
});

export const Route = createFileRoute("/callback")({
  validateSearch: (search: Record<string, unknown>) =>
    callbackSchema.validateSync(search),
  component: () => <CallbackComponent />,
});

/**
 * Handles redirect from bouncer with code or error in the url query.
 * If the code exists, provide it to the OIDC server in exchange for an access token.
 * If the code does not exist, display the error message.
 */
function CallbackComponent() {
  const { apiInstance } = useApi();
  const navigate = useNavigate();
  const { code, error, state } = Route.useSearch();
  const { token, login } = useAuth();
  const [callbackErrorDescription, setCallbackErrorDescription] = useState<
    string | null
  >(null);

  useEffect(() => {
    /** provides a code to the oidc server in exchange for an access token to login user */
    async function exchangeCodeForToken(
      code: string,
      state: string,
    ): Promise<void> {
      try {
        const token = await apiInstance.googleTokenAuthGoogleCodeForTokenPost({
          code,
          state,
        });
        login(token);
      } catch (error) {
        if (isResponseError(error)) {
          console.log(
            "ResponseError",
            error.message,
            error.name,
            error.response,
          );
          if (error.response.status === 401) {
            setCallbackErrorDescription("Unauthorized");
          } else {
            setCallbackErrorDescription(error.message);
          }
        } else if (isFetchError(error)) {
          console.log("FetchError", error.message, error.name);
          setCallbackErrorDescription(error.message);
        } else {
          console.log("UnknownError", error);
          setCallbackErrorDescription("Unknwon Error");
        }
      }
    }

    // if token is already present, navigate back to state where the auth flow began
    if (token) {
      void navigate({ to: state || "/" }).catch(console.error);
    } else if (code && state) {
      void exchangeCodeForToken(code, state).catch(console.error);
    }
  }, [token, code, login, navigate, state, apiInstance]);

  // Display a message if we didn't find a code or there is an error (should be same thing)
  if (error || !code || callbackErrorDescription) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Alert
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() =>
                void navigate({ to: "/login", search: { redirect: "/" } })
              }
            >
              Return to Login
            </Button>
          }
        >
          {callbackErrorDescription}
        </Alert>
      </div>
    );
  }

  // A loading message is displayed in the happy path
  return (
    <div style={{ position: "relative" }}>
      <h1>Logging In...</h1>
      <CircularProgress />
    </div>
  );
}
