import { ApiContext } from "./apiContext";
import React from "react";
import { AuthContext } from "@src/auth/authContext";
import {
  getAuthenticatedApiInstance,
  unAuthenticatedApiInstance,
} from "./apiInstance";

export function ApiProvider({
  auth,
  children,
}: {
  auth: AuthContext;
  children: React.ReactNode;
}) {
  // Memoize the apiInstance based on auth.token
  const apiInstance = React.useMemo(() => {
    if (auth.token) {
      return getAuthenticatedApiInstance(auth.token.access_token);
    } else {
      return unAuthenticatedApiInstance;
    }
  }, [auth.token]);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = React.useMemo(() => ({ apiInstance }), [apiInstance]);

  return (
    <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
  );
}
