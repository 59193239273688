import Layout from "@src/layout";
import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";

/** redirects unauthenticated users to login page for any route inside _auth folder
 *
 * https://tanstack.com/router/latest/docs/framework/react/guide/routing-concepts#pathless-routes
 */
export const Route = createFileRoute("/_auth")({
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
        throw: true,
      });
    }
  },
  component: () => (
    <Layout>
      <Outlet />
    </Layout>
  ),
});
