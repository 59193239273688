import { useAuth } from "./auth/authHook";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { RouterProvider, createRouter } from "@tanstack/react-router";
// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import { ThemeProvider } from "./theme/themeProvider";
import { ThemedApp } from "./theme/themedApp";
import { ApiProvider } from "./apiInstance/apiProvider";

// Create a new query client instance
const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: ONE_WEEK,
    },
  },
});

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  context: {
    auth: undefined!, // This will be set after we wrap the app in an AuthProvider
    queryClient,
  },
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

/** App must be within AuthProvider provided by main.tsx */
export default function App() {
  const auth = useAuth();
  return (
    <ApiProvider auth={auth}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ThemedApp>
            <RouterProvider router={router} context={{ auth }} />
          </ThemedApp>
        </ThemeProvider>
      </QueryClientProvider>
    </ApiProvider>
  );
}
