import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import TimelineIcon from "@mui/icons-material/Timeline";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { CombinedCompanyAffinityModel } from "@src/api/models/CombinedCompanyAffinityModel";
import PeopleIcon from "@mui/icons-material/People";
import FactoryIcon from "@mui/icons-material/Factory";
import ConstructionIcon from "@mui/icons-material/Construction";
import PublicIcon from "@mui/icons-material/Public";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SavingsIcon from "@mui/icons-material/Savings";
import Tooltip from "@mui/material/Tooltip";
import EventIcon from "@mui/icons-material/Event";
import FlagIcon from "@mui/icons-material/Flag";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Grid from "@mui/material/Grid";
import LinkedIn from "@mui/icons-material/LinkedIn";
import { SiCrunchbase } from "react-icons/si";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CallIcon from "@mui/icons-material/Call";

import blue from "@mui/material/colors/blue";
import IconButton from "@mui/material/IconButton";
import { useMutation } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";
import HeadcountPlot from "./headcountPlot";
import { Person } from "@mui/icons-material";
import { differenceInCalendarDays } from "date-fns/differenceInCalendarDays";
import { useTheme } from "@mui/material/styles";
import LinkedInCEO from "./linkedin_ceo";
import { useAuth } from "@src/auth/authHook";
import { useApi } from "@src/apiInstance/apiHook";

const chipStyles = {
  width: 150,
  justifyContent: "start",
  paddingLeft: 0.5,
};

function Headcount({ row }: { row: CombinedCompanyAffinityModel }) {
  return (
    <Tooltip
      title={`Employee Count: ${row.company.company_size_employees_count}`}
      arrow
    >
      <Chip
        icon={<PeopleIcon />}
        label={row.company.company_size_employees_count ?? "?"}
        size="small"
        sx={chipStyles}
      />
    </Tooltip>
  );
}

function LastFundingType({ row }: { row: CombinedCompanyAffinityModel }) {
  return (
    <Tooltip
      title={`Last Round Funding Type: ${row.company.last_round_type}`}
      arrow
    >
      <Chip
        icon={<AccountBalanceIcon />}
        label={row.company.last_round_type ?? "?"}
        size="small"
        sx={chipStyles}
      />
    </Tooltip>
  );
}

function Founded({ row }: { row: CombinedCompanyAffinityModel }) {
  return (
    <Tooltip
      title={`Company Founded: ${row.company.company_founded?.toString() ?? "?"}`}
      arrow
    >
      <Chip
        icon={<EventIcon />}
        label={row.company.company_founded?.toString() ?? "?"}
        size="small"
        sx={chipStyles}
      />
    </Tooltip>
  );
}

function Industry({ row }: { row: CombinedCompanyAffinityModel }) {
  return (
    <Tooltip
      title={`Company Industry: ${row.company.company_industry ?? "?"}`}
      arrow
    >
      <Chip
        icon={<ConstructionIcon />}
        label={row.company.company_industry ?? "?"}
        size="small"
        sx={chipStyles}
      />
    </Tooltip>
  );
}

function IndustryGroup({ row }: { row: CombinedCompanyAffinityModel }) {
  return (
    <Tooltip
      title={`Company Industry Type: ${row.company.company_industry_group ?? "?"}`}
      arrow
    >
      <Chip
        icon={<FactoryIcon />}
        label={row.company.company_industry_group ?? "?"}
        size="small"
        sx={chipStyles}
      />
    </Tooltip>
  );
}

function Name({ row }: { row: CombinedCompanyAffinityModel }) {
  return (
    <Typography sx={{ ml: 1 }} variant="h6">
      {row.company.company_websites_main ? (
        <Link href={row.company.company_websites_main} target="_blank">
          {row.company.company_name}
        </Link>
      ) : (
        row.company.company_name
      )}
    </Typography>
  );
}

function Country({ row }: { row: CombinedCompanyAffinityModel }) {
  return (
    <Tooltip
      title={`HQ Country: ${row.company.company_location_hq_country ?? "?"}`}
      arrow
    >
      <Chip
        icon={<FlagIcon />}
        label={row.company.company_location_hq_country ?? "?"}
        size="small"
        sx={chipStyles}
      />
    </Tooltip>
  );
}

function CountryGroup({ row }: { row: CombinedCompanyAffinityModel }) {
  return (
    <Tooltip
      title={`HQ Region: ${row.company.company_location_hq_country_group ?? "?"}`}
      arrow
    >
      <Chip
        icon={<PublicIcon />}
        label={row.company.company_location_hq_country_group ?? "?"}
        size="small"
        sx={chipStyles}
      />
    </Tooltip>
  );
}

function LastFunding({ row }: { row: CombinedCompanyAffinityModel }) {
  return (
    <Tooltip
      title={`Last Round Money Raised: ${row.company.last_round_money_raised?.toLocaleString() ?? "?"}`}
      arrow
    >
      <Chip
        icon={<AttachMoneyIcon />}
        label={row.company.last_round_money_raised?.toLocaleString() ?? "0"}
        size="small"
        sx={chipStyles}
      />
    </Tooltip>
  );
}

function TotalFunding({ row }: { row: CombinedCompanyAffinityModel }) {
  return (
    <Tooltip
      title={`Total Money Raised: ${row.company.total_money_raised?.toLocaleString() ?? "?"}`}
      arrow
    >
      <Chip
        icon={<SavingsIcon />}
        label={row.company.total_money_raised?.toLocaleString() ?? "0"}
        size="small"
        sx={chipStyles}
      />
    </Tooltip>
  );
}

function Description({ row }: { row: CombinedCompanyAffinityModel }) {
  if (!row.company.company_description) {
    return (
      <Typography sx={{ mt: 1 }} variant="subtitle1">
        No description.
      </Typography>
    );
  }
  return (
    <Typography sx={{ mt: 1 }} variant="subtitle1">
      {row.company.company_description}
    </Typography>
  );
}

function Crunchbase({ row }: { row: CombinedCompanyAffinityModel }) {
  if (!row.company.cb_url) {
    return null;
  }
  return (
    <Tooltip title="Open Crunchbase" arrow>
      <Link
        href={row.company.cb_url}
        target="_blank"
        sx={{ height: 25, width: 25 }}
      >
        <SiCrunchbase size={18} style={{ marginTop: 3, marginLeft: 5 }} />
      </Link>
    </Tooltip>
  );
}

function LinkedInInfo({ row }: { row: CombinedCompanyAffinityModel }) {
  if (!row.company.company_websites_linkedin) {
    return null;
  }
  return (
    <Tooltip title="Open LinkedIn" arrow>
      <Link
        href={row.company.company_websites_linkedin}
        target="_blank"
        sx={{ height: 25, width: 25 }}
      >
        <LinkedIn sx={{ color: blue[800], width: 25, height: 25 }} />
      </Link>
    </Tooltip>
  );
}

function CRMChip({ row }: { row: CombinedCompanyAffinityModel }) {
  const { token } = useAuth();
  const { apiInstance } = useApi();

  const mutation = useMutation({
    mutationFn: () =>
      apiInstance.addToCrmApiAddToCrmPost({
        email: token?.user_info.email ?? "missing_user",
        companyName: row.company.company_name,
        domain: row.company.company_domain,
      }),
    mutationKey: [
      "addToCrm",
      {
        domain: row.company.company_domain,
        companyName: row.company.company_name,
      },
    ],
    onError: (error) => {
      console.error("onError", error);
    },
  });

  if (row.affinity?.id || mutation.isSuccess) {
    const id = row.affinity?.id ?? mutation.data;
    return (
      <Tooltip title="Open In CRM" arrow>
        <Link
          href={`https://anfa.affinity.co/companies/${id}`}
          target="_blank"
          sx={{ height: 25, width: 25 }}
        >
          <CheckBoxIcon sx={{ height: 25, width: 25 }} color="success" />
        </Link>
      </Tooltip>
    );
  }

  if (mutation.isPending) {
    return <CircularProgress size={22} color="success" />;
  }

  return (
    <Tooltip
      title={
        row.company.company_domain !== null
          ? "Add to CRM"
          : "No domain found, cannot add to CRM"
      }
      arrow
    >
      <span>
        <IconButton
          size="medium"
          sx={{
            width: 25,
            height: 25,
            m: 0,
            p: 0,
          }}
          color="info"
          onClick={() => mutation.mutate()}
          disabled={row.company.company_domain === null}
        >
          <AddBoxIcon sx={{ height: 25, width: 25 }} />
        </IconButton>
      </span>
    </Tooltip>
  );
}

function HeadcountGrowth({
  row,
  months,
}: {
  row: CombinedCompanyAffinityModel;
  months: number;
}) {
  const growth =
    months === 1
      ? row.company.growth_pct_1m
      : months === 3
        ? row.company.growth_pct_3m
        : row.company.growth_pct_6m;

  return (
    <Tooltip
      title={`Headcount Growth for last ${months} month: ${growth}%`}
      arrow
    >
      <Chip
        icon={<TimelineIcon />}
        label={growth ? `${growth}%` : "?"}
        size="small"
        sx={chipStyles}
      />
    </Tooltip>
  );
}

function LastInteractor({ row }: { row: CombinedCompanyAffinityModel }) {
  const last_interactors =
    row.affinity?.last_interactors?.join(", ") ?? "Unknown";
  return (
    <Tooltip title={`Last Interactors: ${last_interactors}`} arrow>
      <Chip
        icon={<Person />}
        label={last_interactors}
        size="small"
        sx={chipStyles}
      />
    </Tooltip>
  );
}

function LastInteractionType({ row }: { row: CombinedCompanyAffinityModel }) {
  const interaction_type = row.affinity?.interaction_type ?? "Unknown";
  return (
    <Tooltip title={`Last Interaction Type: ${interaction_type}`} arrow>
      <Chip
        icon={<CallIcon />}
        label={interaction_type}
        size="small"
        sx={chipStyles}
      />
    </Tooltip>
  );
}

function LastInteractionDate({ row }: { row: CombinedCompanyAffinityModel }) {
  const last_interaction_date = row.affinity?.last_interaction_date
    ? new Date(row.affinity.last_interaction_date).toLocaleDateString()
    : "Unknown";
  return (
    <Tooltip title={`Last Interaction Date: ${last_interaction_date}`} arrow>
      <Chip
        icon={<EventIcon />}
        label={last_interaction_date}
        size="small"
        sx={chipStyles}
      />
    </Tooltip>
  );
}

function LastInteractionDaysAgo({
  row,
}: {
  row: CombinedCompanyAffinityModel;
}) {
  const lastInteractionDate = row.affinity?.last_interaction_date
    ? new Date(row.affinity.last_interaction_date)
    : null;

  const today = new Date();

  const daysAgo = lastInteractionDate
    ? differenceInCalendarDays(today, lastInteractionDate) + 1
    : null;

  return (
    <Tooltip
      title={`Last Interaction Days Ago: ${lastInteractionDate?.toLocaleDateString() || "No interaction date"}`}
      arrow
    >
      <Chip
        icon={<EventIcon />}
        label={daysAgo !== null ? `${daysAgo} days ago` : "No interaction"}
        size="small"
        sx={chipStyles}
      />
    </Tooltip>
  );
}

function NameAndLinks({ row }: { row: CombinedCompanyAffinityModel }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Name row={row} />
      <div style={{ display: "flex", flexDirection: "row", marginLeft: 2 }}>
        <Crunchbase row={row} />
        <LinkedInInfo row={row} />
        <LinkedInCEO row={row} />
        <CRMChip row={row} />
      </div>
    </div>
  );
}

export default function CompanyRow({
  row,
}: {
  row: CombinedCompanyAffinityModel;
}) {
  const theme = useTheme();

  return (
    <TableRow key={row.company.company_id}>
      <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", width: "30%" }}>
            <img
              style={{ borderRadius: 3 }}
              src={`data:image/png;base64,${row.company.company_logo}`}
              alt="company_logo"
              height={70}
              width={70}
            />
            <NameAndLinks row={row} />
          </div>
          <Grid
            container
            rowSpacing={0}
            rowGap={1}
            columnSpacing={1}
            sx={{ width: "70%" }}
          >
            <Grid item xs={3}>
              <Headcount row={row} />
            </Grid>
            <Grid item xs={3}>
              <HeadcountGrowth row={row} months={1} />
            </Grid>
            <Grid item xs={3}>
              <HeadcountGrowth row={row} months={3} />
            </Grid>
            <Grid item xs={3}>
              <HeadcountGrowth row={row} months={6} />
            </Grid>
            {/* 4 Columns. Second row starts here*/}
            <Grid item xs={3}>
              <IndustryGroup row={row} />
            </Grid>
            <Grid item xs={3}>
              <CountryGroup row={row} />
            </Grid>
            <Grid item xs={3}>
              <LastFundingType row={row} />
            </Grid>
            <Grid item xs={3}>
              <Founded row={row} />
            </Grid>
            {/* 3rd row starts here? */}
            <Grid item xs={3}>
              <Industry row={row} />
            </Grid>
            <Grid item xs={3}>
              <Country row={row} />
            </Grid>
            <Grid item xs={3}>
              <LastFunding row={row} />
            </Grid>
            <Grid item xs={3}>
              <TotalFunding row={row} />
            </Grid>
            {/* 4th row starts here? */}
            {row.affinity && (
              <>
                <Grid item xs={3}>
                  <LastInteractor row={row} />
                </Grid>
                <Grid item xs={3}>
                  <LastInteractionType row={row} />
                </Grid>
                <Grid item xs={3}>
                  <LastInteractionDate row={row} />
                </Grid>
                <Grid item xs={3}>
                  <LastInteractionDaysAgo row={row} />
                </Grid>
              </>
            )}
          </Grid>
        </div>
        <HeadcountPlot row={row} />
        <Description row={row} />
      </TableCell>
    </TableRow>
  );
}
