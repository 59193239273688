/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Schema for the headcount dataset
 * @export
 * @interface HeadcountModel
 */
export interface HeadcountModel {
    /**
     * 
     * @type {number}
     * @memberof HeadcountModel
     */
    company_id: number;
    /**
     * 
     * @type {string}
     * @memberof HeadcountModel
     */
    created: string;
    /**
     * 
     * @type {number}
     * @memberof HeadcountModel
     */
    headcount: number;
}

/**
 * Check if a given object implements the HeadcountModel interface.
 */
export function instanceOfHeadcountModel(value: object): value is HeadcountModel {
    if (!('company_id' in value) || value['company_id'] === undefined) return false;
    if (!('created' in value) || value['created'] === undefined) return false;
    if (!('headcount' in value) || value['headcount'] === undefined) return false;
    return true;
}

export function HeadcountModelFromJSON(json: any): HeadcountModel {
    return HeadcountModelFromJSONTyped(json, false);
}

export function HeadcountModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeadcountModel {
    if (json == null) {
        return json;
    }
    return {
        
        'company_id': json['company_id'],
        'created': json['created'],
        'headcount': json['headcount'],
    };
}

export function HeadcountModelToJSON(value?: HeadcountModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company_id': value['company_id'],
        'created': value['created'],
        'headcount': value['headcount'],
    };
}

