import { companyFilterRequestSchema } from "@src/schemas";
import { createFileRoute, Outlet } from "@tanstack/react-router";

/** A pathless route to add filters to child routes */
export const Route = createFileRoute("/_auth/_filtered")({
  validateSearch: (search: Record<string, unknown>) =>
    companyFilterRequestSchema.validateSync(search, {
      stripUnknown: true,
    }),
  component: () => <Outlet />,
});
