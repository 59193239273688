import { Alert, LinearProgress, useTheme } from "@mui/material";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";
import { CombinedCompanyAffinityModel } from "@src/api";
import { useApi } from "@src/apiInstance/apiHook";
import { useQuery } from "@tanstack/react-query";

interface DataPoint {
  x: Date;
  y: number;
}

export default function HeadcountPlot({
  row,
}: {
  row: CombinedCompanyAffinityModel;
}) {
  const theme = useTheme();
  const { apiInstance } = useApi();
  const { isError, isFetching, data, error } = useQuery({
    queryKey: ["headcount", row.company.company_id],
    queryFn: () =>
      apiInstance.getHeadcountApiHeadcountGet({
        companyId: row.company.company_id,
      }),
  });

  if (isError) {
    console.error(error);
    return <Alert severity="error">Could not fetch headcount info</Alert>;
  }

  if (isFetching) {
    return <LinearProgress />;
  }

  if (!data || data.length <= 1) {
    return (
      <Alert severity="info" sx={{ m: 2, bgcolor: "background.paper" }}>
        No headcount data
      </Alert>
    );
  }

  const transformedData: DataPoint[] = data.map((data) => ({
    x: new Date(data.created), // Convert the date to unix timestamp
    y: data.headcount,
  }));

  if (transformedData.length === 0) {
    return null;
  }

  return (
    <SparkLineChart
      margin={{ top: 5, bottom: 5, left: 0, right: 5 }}
      height={50}
      xAxis={{
        data: transformedData.map((d) => d.x),
        scaleType: "time",
        valueFormatter: (value: Date) => value.toISOString().slice(0, 10),
      }}
      data={transformedData.map((d) => d.y)}
      showTooltip={true}
      showHighlight={true}
      colors={[theme.palette.primary.dark]}
    />
  );
}
