import * as React from "react";
import { ApiContext } from "./apiContext";

/** useAuth hook provides access to the auth context */
export function useApi() {
  const context = React.useContext(ApiContext);
  if (!context) {
    throw new Error("useApi must be used within an AuthProvider");
  }
  return context;
}
