import { TokenResponse } from "@src/api";
import { createContext } from "react";

export interface AuthContext {
  isAuthenticated: boolean;
  openGoogleLogin: (state: string) => Promise<null | string>;
  logout: () => void;
  login: (token: TokenResponse) => void;
  token: TokenResponse | null;
}

export const AuthContext = createContext<AuthContext | null>(null);
