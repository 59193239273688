import { CompanyFilterRequest, Configuration, DefaultApi } from "@src/api";
import { queryOptions } from "@tanstack/react-query";
import { useApi } from "./apiInstance/apiHook";

const BACKEND_HOST = import.meta.env.VITE_BACKEND_HOST as string;
const configuration = new Configuration({ basePath: BACKEND_HOST });

export const unAuthenticatedApiInstance = new DefaultApi(configuration);

export function CompanyColumnQueryOptions(
  column_name: keyof CompanyFilterRequest,
) {
  const { apiInstance } = useApi();

  return queryOptions({
    queryKey: ["distinct", column_name],
    queryFn: () =>
      apiInstance.getDistinctValuesApiDistinctGet({ column: column_name }),
  });
}

export function AffinityLastInteractorsQueryOptions() {
  const { apiInstance } = useApi();
  return queryOptions({
    queryKey: ["affinity_last_interactors"],
    queryFn: () =>
      apiInstance.getDistinctInteractorsApiDistinctInteractorsGet(),
  });
}

export function AffinityLastInteractionTypeQueryOptions() {
  const { apiInstance } = useApi();
  return queryOptions({
    queryKey: ["affinity_last_interaction_type"],
    queryFn: () =>
      apiInstance.getDistinctInteractionTypesApiDistinctInteractionTypesGet(),
  });
}
