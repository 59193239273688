import React, { useCallback, useMemo } from "react";
import { AuthContext } from "./authContext";
import {
  TokenResponse,
  TokenResponseFromJSON,
  TokenResponseToJSON,
} from "@src/api";
import { unAuthenticatedApiInstance } from "@src/api_instance";

const key = "tanstack.auth.user";

/**
 * Get the token from local storage, checking for expiration.
 * @returns The token string if valid, otherwise null.
 */
function getStoredToken(): TokenResponse | null {
  console.log("getStoredToken");
  const tokenString = localStorage.getItem(key);

  if (!tokenString) {
    console.log("no token found");
    return null; // No token found
  }

  try {
    const tokenObject: TokenResponse = TokenResponseFromJSON(
      JSON.parse(tokenString),
    );

    // const expireDateStr = new Date(tokenObject.expiresAt).toLocaleString();
    // console.log("Token found, expires at: ", expireDateStr);

    return tokenObject; // Token is valid
  } catch (error) {
    console.error("Error parsing token from localStorage:", error);
    // In case of parsing error, remove the corrupted token
    localStorage.removeItem(key);
    return null;
  }
}

/**
 * Store the user's access token in local storage with an expiration time.
 * @param token The token string to store. If null, the token is removed.
 */
function setStoredToken(token: TokenResponse | null): void {
  if (token) {
    localStorage.setItem(key, JSON.stringify(TokenResponseToJSON(token)));
  } else {
    // Remove the token if user is null
    localStorage.removeItem(key);
  }
}

/** Redirect the user to login with bouncer. Save where the user came from in redirect */
async function openGoogleLogin(state: string): Promise<string | null> {
  try {
    const url =
      await unAuthenticatedApiInstance.googleLoginAuthGoogleLoginUrlGet({
        state,
      });
    window.location.href = url;
    return null;
  } catch (error) {
    console.error("Error getting Google login URL:", error);
    return "Error getting Google login URL";
  }
}

/** AuthProvider stores information about the user's authentication state */
export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = React.useState<TokenResponse | null>(
    getStoredToken(),
  );
  const isAuthenticated = !!token;

  /** Remove token from storage and update state to recalculate isAuthenticated */
  const logout = useCallback(() => {
    setStoredToken(null);
    setToken(null);
  }, []);

  /** Add the token to local storage and update state to recalculate isAuthenticated */
  const login = useCallback((token: TokenResponse) => {
    setStoredToken(token);
    setToken(token);
  }, []);

  /** Memoize the context to prevent unnecessary rerenders */
  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      token,
      openGoogleLogin,
      logout,
      login,
    }),
    [isAuthenticated, token, logout, login],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}
