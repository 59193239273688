import * as React from "react";
import { AuthContext } from "./authContext";

/** useAuth hook provides access to the auth context */
export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
