import { useEffect, useState } from "react";
import { ToggleColorMode } from "./themeToggle";
import { Logout } from "@mui/icons-material";
import { useTheme } from "../theme/themeHook";
import { useRouter } from "@tanstack/react-router";

import {
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme as useMuiTheme,
} from "@mui/material";
import { useAuth } from "@src/auth/authHook";

export default function UserMenu() {
  const { token, logout } = useAuth();
  const router = useRouter();
  const theme = useMuiTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { mode, toggleTheme } = useTheme();
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const logoutAndClose = () => {
    logout();
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!token) {
      router.invalidate().catch(console.error);
    }
  }, [router, token]);

  return (
    <>
      <IconButton
        onClick={handleMenu}
        sx={{ height: "1.5em", width: "1.5em", m: 0, p: 0 }}
      >
        <Avatar
          src={token?.user_info.picture}
          sx={{
            height: "100%",
            width: "100%",
            borderRadius: theme.shape.borderRadius,
          }}
          variant="square"
        />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={logoutAndClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
        <ToggleColorMode mode={mode} toggleColorMode={toggleTheme} />
      </Menu>
    </>
  );
}
