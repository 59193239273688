import { createFileRoute, redirect } from "@tanstack/react-router";

import { useAuth } from "../auth/authHook";
import { useState } from "react";
import * as yup from "yup";
import { Alert, CircularProgress, Typography } from "@mui/material";
import GoogleButton from "@src/components/googleButton";

const searchSchema = yup.object({
  redirect: yup.string().optional().default(""),
});

export const Route = createFileRoute("/login")({
  validateSearch: (search: Record<string, unknown>) =>
    searchSchema.validateSync(search),
  beforeLoad: ({ context, search }) => {
    if (context.auth.isAuthenticated) {
      redirect({ to: "/", throw: true, search });
    }
  },
  component: LoginComponent,
});

/** Display the login page with a button to login with bouncer */
function LoginComponent() {
  const { redirect } = Route.useSearch();
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();

  /** login the user and show a loading indicator */
  async function login(): Promise<void> {
    setLoading(true);
    const errorResponse = await auth.openGoogleLogin(redirect);
    if (errorResponse) {
      setError(errorResponse);
      setLoading(false);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        position: "relative",
      }}
    >
      {loading && <CircularProgress />}
      <Typography variant="h6" sx={{ mb: 3 }}>
        Welcome to Anfa Bazaar
      </Typography>
      <GoogleButton onClick={() => void login()} />
      {error && <Alert severity="error">{error}</Alert>}
    </div>
  );
}
