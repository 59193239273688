/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * User info from Google OAuth
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    verified_email: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    given_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    family_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    picture: string;
}

/**
 * Check if a given object implements the UserInfo interface.
 */
export function instanceOfUserInfo(value: object): value is UserInfo {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('verified_email' in value) || value['verified_email'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('given_name' in value) || value['given_name'] === undefined) return false;
    if (!('family_name' in value) || value['family_name'] === undefined) return false;
    if (!('picture' in value) || value['picture'] === undefined) return false;
    return true;
}

export function UserInfoFromJSON(json: any): UserInfo {
    return UserInfoFromJSONTyped(json, false);
}

export function UserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'verified_email': json['verified_email'],
        'name': json['name'],
        'given_name': json['given_name'],
        'family_name': json['family_name'],
        'picture': json['picture'],
    };
}

export function UserInfoToJSON(value?: UserInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email': value['email'],
        'verified_email': value['verified_email'],
        'name': value['name'],
        'given_name': value['given_name'],
        'family_name': value['family_name'],
        'picture': value['picture'],
    };
}

