/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CombinedCompanyAffinityModel,
  CompanyFilterRequest,
  HTTPValidationError,
  HeadcountModel,
  RangeRequest,
  TokenResponse,
} from '../models/index';
import {
    CombinedCompanyAffinityModelFromJSON,
    CombinedCompanyAffinityModelToJSON,
    CompanyFilterRequestFromJSON,
    CompanyFilterRequestToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    HeadcountModelFromJSON,
    HeadcountModelToJSON,
    RangeRequestFromJSON,
    RangeRequestToJSON,
    TokenResponseFromJSON,
    TokenResponseToJSON,
} from '../models/index';

export interface AddToCrmApiAddToCrmPostRequest {
    email: string;
    companyName: string;
    domain?: string | null;
}

export interface CountCompaniesApiCountCompaniesPostRequest {
    companyFilterRequest: CompanyFilterRequest;
}

export interface GetCompaniesApiCompaniesPostRequest {
    companyFilterRequest: CompanyFilterRequest;
    page?: number;
    pageSize?: number;
}

export interface GetDistinctValuesApiDistinctGetRequest {
    column?: string;
}

export interface GetHeadcountApiHeadcountGetRequest {
    companyId: number;
}

export interface GetRangeApiRangeGetRequest {
    column?: string;
}

export interface GoogleLoginAuthGoogleLoginUrlGetRequest {
    state: string;
}

export interface GoogleTokenAuthGoogleCodeForTokenPostRequest {
    code: string;
    state?: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Add a company to the CRM via domain
     * Add To Crm
     */
    async addToCrmApiAddToCrmPostRaw(requestParameters: AddToCrmApiAddToCrmPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling addToCrmApiAddToCrmPost().'
            );
        }

        if (requestParameters['companyName'] == null) {
            throw new runtime.RequiredError(
                'companyName',
                'Required parameter "companyName" was null or undefined when calling addToCrmApiAddToCrmPost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['companyName'] != null) {
            queryParameters['company_name'] = requestParameters['companyName'];
        }

        if (requestParameters['domain'] != null) {
            queryParameters['domain'] = requestParameters['domain'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2AuthorizationCodeBearer", []);
        }

        const response = await this.request({
            path: `/api/add_to_crm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add a company to the CRM via domain
     * Add To Crm
     */
    async addToCrmApiAddToCrmPost(requestParameters: AddToCrmApiAddToCrmPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.addToCrmApiAddToCrmPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Although fetching data, this is post because the filters are complicated enough for body
     * Count Companies
     */
    async countCompaniesApiCountCompaniesPostRaw(requestParameters: CountCompaniesApiCountCompaniesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['companyFilterRequest'] == null) {
            throw new runtime.RequiredError(
                'companyFilterRequest',
                'Required parameter "companyFilterRequest" was null or undefined when calling countCompaniesApiCountCompaniesPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2AuthorizationCodeBearer", []);
        }

        const response = await this.request({
            path: `/api/count_companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyFilterRequestToJSON(requestParameters['companyFilterRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Although fetching data, this is post because the filters are complicated enough for body
     * Count Companies
     */
    async countCompaniesApiCountCompaniesPost(requestParameters: CountCompaniesApiCountCompaniesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countCompaniesApiCountCompaniesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Although fetching data, this is post because the filters are complicated enough for body
     * Get Companies
     */
    async getCompaniesApiCompaniesPostRaw(requestParameters: GetCompaniesApiCompaniesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CombinedCompanyAffinityModel>>> {
        if (requestParameters['companyFilterRequest'] == null) {
            throw new runtime.RequiredError(
                'companyFilterRequest',
                'Required parameter "companyFilterRequest" was null or undefined when calling getCompaniesApiCompaniesPost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2AuthorizationCodeBearer", []);
        }

        const response = await this.request({
            path: `/api/companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyFilterRequestToJSON(requestParameters['companyFilterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CombinedCompanyAffinityModelFromJSON));
    }

    /**
     * Although fetching data, this is post because the filters are complicated enough for body
     * Get Companies
     */
    async getCompaniesApiCompaniesPost(requestParameters: GetCompaniesApiCompaniesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CombinedCompanyAffinityModel>> {
        const response = await this.getCompaniesApiCompaniesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get distinct values for a column
     * Get Distinct Interaction Types
     */
    async getDistinctInteractionTypesApiDistinctInteractionTypesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2AuthorizationCodeBearer", []);
        }

        const response = await this.request({
            path: `/api/distinct_interaction_types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get distinct values for a column
     * Get Distinct Interaction Types
     */
    async getDistinctInteractionTypesApiDistinctInteractionTypesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getDistinctInteractionTypesApiDistinctInteractionTypesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get distinct values for a column
     * Get Distinct Interactors
     */
    async getDistinctInteractorsApiDistinctInteractorsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2AuthorizationCodeBearer", []);
        }

        const response = await this.request({
            path: `/api/distinct_interactors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get distinct values for a column
     * Get Distinct Interactors
     */
    async getDistinctInteractorsApiDistinctInteractorsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getDistinctInteractorsApiDistinctInteractorsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get distinct values for a column
     * Get Distinct Values
     */
    async getDistinctValuesApiDistinctGetRaw(requestParameters: GetDistinctValuesApiDistinctGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters['column'] != null) {
            queryParameters['column'] = requestParameters['column'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2AuthorizationCodeBearer", []);
        }

        const response = await this.request({
            path: `/api/distinct`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get distinct values for a column
     * Get Distinct Values
     */
    async getDistinctValuesApiDistinctGet(requestParameters: GetDistinctValuesApiDistinctGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getDistinctValuesApiDistinctGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return array of headcount values and their timestamps
     * Get Headcount
     */
    async getHeadcountApiHeadcountGetRaw(requestParameters: GetHeadcountApiHeadcountGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HeadcountModel>>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling getHeadcountApiHeadcountGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['company_id'] = requestParameters['companyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2AuthorizationCodeBearer", []);
        }

        const response = await this.request({
            path: `/api/headcount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HeadcountModelFromJSON));
    }

    /**
     * Return array of headcount values and their timestamps
     * Get Headcount
     */
    async getHeadcountApiHeadcountGet(requestParameters: GetHeadcountApiHeadcountGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HeadcountModel>> {
        const response = await this.getHeadcountApiHeadcountGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get range for a numeric column
     * Get Range
     */
    async getRangeApiRangeGetRaw(requestParameters: GetRangeApiRangeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RangeRequest>> {
        const queryParameters: any = {};

        if (requestParameters['column'] != null) {
            queryParameters['column'] = requestParameters['column'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2AuthorizationCodeBearer", []);
        }

        const response = await this.request({
            path: `/api/range`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RangeRequestFromJSON(jsonValue));
    }

    /**
     * Get range for a numeric column
     * Get Range
     */
    async getRangeApiRangeGet(requestParameters: GetRangeApiRangeGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RangeRequest> {
        const response = await this.getRangeApiRangeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return the URL to redirect to for Google OAuth
     * Google Login
     */
    async googleLoginAuthGoogleLoginUrlGetRaw(requestParameters: GoogleLoginAuthGoogleLoginUrlGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['state'] == null) {
            throw new runtime.RequiredError(
                'state',
                'Required parameter "state" was null or undefined when calling googleLoginAuthGoogleLoginUrlGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['state'] != null) {
            queryParameters['state'] = requestParameters['state'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/google/login_url`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Return the URL to redirect to for Google OAuth
     * Google Login
     */
    async googleLoginAuthGoogleLoginUrlGet(requestParameters: GoogleLoginAuthGoogleLoginUrlGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.googleLoginAuthGoogleLoginUrlGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exchange Google OAuth code for backend JWT token
     * Google Token
     */
    async googleTokenAuthGoogleCodeForTokenPostRaw(requestParameters: GoogleTokenAuthGoogleCodeForTokenPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenResponse>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling googleTokenAuthGoogleCodeForTokenPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['code'] != null) {
            formParams.append('code', requestParameters['code'] as any);
        }

        if (requestParameters['state'] != null) {
            formParams.append('state', requestParameters['state'] as any);
        }

        const response = await this.request({
            path: `/auth/google/code_for_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     * Exchange Google OAuth code for backend JWT token
     * Google Token
     */
    async googleTokenAuthGoogleCodeForTokenPost(requestParameters: GoogleTokenAuthGoogleCodeForTokenPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenResponse> {
        const response = await this.googleTokenAuthGoogleCodeForTokenPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
